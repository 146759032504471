export const MODE = {
  LOCAL: "build-local",
  QA: "build-qa",
  PROD: "build-prod",
};

export const API_URL = {
  [MODE.LOCAL]: "http://localhost:8080/UrbanLife/service/api/v3.0",
  [MODE.QA]: "https://qaapi.dailykarma.io/UrbanLife/service/api/v3.0",
  [MODE.PROD]: "https://app.dailykarma.io/UrbanLife/service/api/v3.0",
};

export const MIXPANEL_TOKENS = {
  [MODE.LOCAL]: "9e669a68c5c58be1155ba220666019ef",
  [MODE.QA]: "9e669a68c5c58be1155ba220666019ef",
  [MODE.PROD]: "a6c0abcb7b5e56fdacf7220338c4ae0c",
};
