import React, { useEffect, useState } from "react";
import ScrollWatch from "../utils/scrollWatch";

/**
 * @export
 * @class ScrollNav
 * @extends {PureComponent<any, any>}
 */
const ScrollNav: React.FC<{ scrollPageThreshold: number; children?: React.ReactNode }> = ({ scrollPageThreshold, children }) => {
  const [isVisible, setVisible] = useState(false);

  //#region Events

  useEffect(() => {
    const scrollWatch = new ScrollWatch((scrollY) => {
      setVisible((oldVisible) => (scrollY < scrollPageThreshold === oldVisible ? scrollY >= scrollPageThreshold : oldVisible));
    });
    scrollWatch.start();
    return () => {
      scrollWatch.stop();
    };
  }, [setVisible]);

  return <div className={isVisible ? "header scroll-head" : "header"}>{children}</div>;
};

export default ScrollNav;
