import React, { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { getApiUrl } from "../utils/api";

type CharityType = {
  id: number;
  name: string;
  category: string;
  description: string;
  establishedOn: Date;
  address: {
    address: string;
    phone: string;
    web: string;
    location: {
      latitude: number;
      longitude: number;
    };
  };
  featured: boolean;
  charityTypeId: number;
  photoUrl: string;
  thumbnailUrl: string;
  fbPageLink: string;
  twitterPageLink: string;
  registrationId: string;
  status: string;
  fundraisingEfficiency: number;
  starRating: number;
};

function Charity() {
  const { charityId } = useParams<{ charityId: string }>();
  const [charity, setCharity] = useState<CharityType>();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${getApiUrl()}/charities/${charityId}`, {
      method: "GET",
    })
      .then(async (response) => {
        if (response.ok) {
          setCharity(await response.json());
        } else {
          navigate("/404");
        }
      })
      .catch(() => {
        navigate("/404");
      });
  }, [charityId, setCharity]);

  const renderFundraisingEfficiencyPopover = () => {
    return (
      <Popover id='popover-trigger-focus-f'>
        The Fundraising Efficiency is the amount spent to raise $1 in charitable contributions. To calculate a charity's fundraising
        efficiency, the average fundraising expenses are divided by the average total contributions it receives. The charity's average
        expenses and average contributions are calculated over its three most recent fiscal years. This data is sourced from our partner
        Charity Navigator.
      </Popover>
    );
  };

  const renderStarRatingPopover = () => {
    return (
      <Popover id='popover-trigger-focus-p'>
        The star ratings measure the financial health and Accountability and Transparency of charities. Each charity rating ranges from 0 to
        4 stars for its Financial Health, Accountability and Transparency, and Overall Rating. The ratings indicate how well a charity meets
        industry-accepted standards and how well it compares with the other charities evaluated. This data is sourced from our partner
        Charity Navigator.
      </Popover>
    );
  };

  const renderFundraisingEfficiency = () => {
    const fundraisingEfficiency = charity?.fundraisingEfficiency;

    return fundraisingEfficiency ? `$${(Number(fundraisingEfficiency) / 100).toFixed(2)}` : "Unrated";
  };

  const renderCharityInformation = () => {
    return (
      charity && (
        <div>
          <div className='info-block'>
            <h4>INFORMATION</h4>
          </div>

          <div className='link-small-text'>
            {charity.address.address}
            <br />
            {charity.address.phone}
            <br />
            <a href={charity.address.web} target='_blank'>
              {charity.address.web}
            </a>
          </div>

          <div className='info-block'>
            <div>
              <strong>Fundraising Efficiency:</strong> {renderFundraisingEfficiency()}
              <div className='pull-right'>
                <OverlayTrigger
                  trigger={["hover", "focus", "click"]}
                  rootClose
                  placement='top'
                  overlay={renderFundraisingEfficiencyPopover()}
                >
                  <span className='glyphicon glyphicon-question-sign inline-icon' />
                </OverlayTrigger>
              </div>
            </div>

            <div>
              <strong>Star Rating:</strong>
              {renderStarRating()}
              <div className='pull-right'>
                <OverlayTrigger trigger={["hover", "focus", "click"]} rootClose placement='top' overlay={renderStarRatingPopover()}>
                  <span className='glyphicon glyphicon-question-sign inline-icon' />
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className='social-icons-block'>
            {charity.fbPageLink && (
              <a href={charity.fbPageLink} target='_blank'>
                <img src='/images/charity/facebook-pink.svg' alt='Facebook logo' className='social-icons' />
              </a>
            )}

            {charity.twitterPageLink && (
              <a href={charity.twitterPageLink} target='_blank'>
                <img src='/images/charity/twitter-pink.svg' alt='Twitter logo' className='social-icons' />
              </a>
            )}
          </div>

          <div className='info-block'>
            Is this your charity? <a href='mailto:charity@dailykarma.com'>Claim it here</a>
          </div>
        </div>
      )
    );
  };

  const renderStarRating = () => {
    if (!charity?.starRating) {
      return <span className='stars-wrapper'>Unrated</span>;
    }

    const stars = new Array(charity.starRating)
      .fill(0)
      .map((item, index) => <img src='/images/icons/star.svg' key={index} alt='Icon for rating star' />);

    return <span className='stars-wrapper'>{stars}</span>;
  };

  const renderMainSection = () => {
    return charity ? (
      <div className='col-md-9'>
        <div className='logo-wrapper'>
          <div className='image-wrapper'>
            <div className='image-holder'>
              <img src={charity.thumbnailUrl} alt={`Thumbnail of charity '${charity.name}'`} />
            </div>
          </div>
        </div>

        <h1>{charity.name}</h1>
        <p className='text-large'>{charity.description}</p>

        <div className='visible-xs text-center'>{renderCharityInformation()}</div>
      </div>
    ) : (
      <div className='loading-overlay'>
        <div className='loader-wrapper'>
          <div className='loader normal'>
            <svg className='circular' viewBox='25 25 50 50'>
              <circle className='path' cx='50' cy='50' r='20' fill='none' strokeWidth='3' />
            </svg>
          </div>
        </div>
      </div>
    );
  };
  function renderSidebar() {
    return <div className='col-md-3 hidden-xs'>{renderCharityInformation()}</div>;
  }

  return (
    <div className='discover-charity'>
      <div className='nav-bg'></div>

      <div className='container'>
        <div className='row'>
          {renderMainSection()}
          {renderSidebar()}
        </div>
      </div>
    </div>
  );
}

export default Charity;
