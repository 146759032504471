import raf from "raf";

export default class ScrollWatch {
  onScroll?: (scrollY: number) => void = undefined;

  isNeedUpdate = true;

  constructor(onScroll: (scrollY: number) => void) {
    this.onScroll = onScroll;
  }

  start() {
    window.addEventListener("scroll", this.onScrollChange);
  }

  stop() {
    window.removeEventListener("scroll", this.onScrollChange);
  }

  onScrollChange = () => {
    if (this.isNeedUpdate) {
      this.isNeedUpdate = false;

      raf(this.onScrollUpdated);
    }
  };

  onScrollUpdated = () => {
    const scrollY = this.getScrollY();

    if (this.onScroll) {
      this.onScroll(scrollY);
    }

    this.isNeedUpdate = true;
  };

  getScrollY() {
    if (window.scrollY !== undefined) {
      return window.scrollY;
    }

    const scrollHost = document.documentElement || document.body.parentNode || document.body;
    return scrollHost.scrollTop;
  }
}
