import React, { useEffect, useState } from "react";
import Slider from "../utils/slider";

const HomeSlider = () => {
  const [sliderClassName, setSliderClassName] = useState<string>("slide-0");

  useEffect(() => {
    const slider = new Slider((slideId: number) => setSliderClassName(`slide-${slideId}`));
    slider.start();
    return () => {
      slider.stop();
    };
  }, []);
  function renderBackground() {
    return (
      <div className='bg-container'>
        <div className='slider-bg slider-bg-4'>
          <img src='/images/home/header_img_4_full.jpg' alt='Slider background 4' />
        </div>
        <div className='slider-bg slider-bg-3'>
          <img src='/images/home/header_img_3_full.jpg' alt='Slider background 3' />
        </div>
        <div className='slider-bg slider-bg-2'>
          <img src='/images/home/header_img_2_full.jpg' alt='Slider background 2' />
        </div>
        <div className='slider-bg slider-bg-1'>
          <img src='/images/home/header_img_1_full.jpg' alt='Slider background 1' />
        </div>
      </div>
    );
  }

  function renderContent() {
    return (
      <div className='container slider-content'>
        <div className='row'>
          <div className='col-lg-4 col-md-6 pull-right'>
            <div className='slider-photo-wrapper'>
              <div className='slider-circular' />
              <div className='slider-photo'>
                <div className='slider-photo-holder slider-photo-4'>
                  <img src='/images/home/header_img_4_circle@2x.jpg' alt='Slider circle image 4' />
                </div>
                <div className='slider-photo-holder slider-photo-3'>
                  <img src='/images/home/header_img_3_circle@2x.jpg' alt='Slider circle image 3' />
                </div>
                <div className='slider-photo-holder slider-photo-2'>
                  <img src='/images/home/header_img_2_circle@2x.jpg' alt='Slider circle image 2' />
                </div>
                <div className='slider-photo-holder slider-photo-1'>
                  <img src='/images/home/header_img_1_circle@2x.jpg' alt='Slider circle image 1' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={`home-wall ${sliderClassName}`}>
      {renderBackground()}
      {renderContent()}
    </div>
  );
};

export default HomeSlider;
