export default class Slider {
  onSlide?: (slideId: number) => void = undefined;

  delay = 1000;
  step = 3000;

  timeouts?: Array<any> = undefined;
  interval?: any = undefined;

  constructor(onSlide: (slideId: number) => void) {
    this.onSlide = onSlide;
  }

  start() {
    this.once();
    this.schedule();
  }

  stop() {
    this.timeouts?.forEach(clearTimeout);
    clearInterval(this.interval);
  }

  once() {
    this.onSlide?.(0);

    this.timeouts = [];
    this.slide(1, this.delay);
    this.slide(2, this.step);
    this.slide(3, this.step * 2);
    this.slide(4, this.step * 3);
  }

  slide(slideId: number, timeout: any) {
    const event = () => this.onSlide?.(slideId);

    this.timeouts?.push(setTimeout(event, timeout));
  }

  schedule() {
    const event = () => this.once();

    this.interval = setInterval(event, this.step * 4);
  }
}
