import React, { useState } from "react";
import ScrollNav from "./ScrollNav";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

function Header() {
  const [isMobileHeaderExpanded, setMobileHeaderExpanded] = useState(false);
  const onNavbarToggle = () => {
    setMobileHeaderExpanded((oldState) => !oldState);
  };

  return (
    <ScrollNav scrollPageThreshold={50}>
      <Navbar
        collapseOnSelect
        className={isMobileHeaderExpanded ? "expanded" : undefined}
        expanded={isMobileHeaderExpanded}
        onToggle={onNavbarToggle}
      >
        <div className='wrapper'>
          <Navbar.Brand>
            <a className='navbar-brand' href='https://www.dailykarma.com/'>
              <i className='icon-logo-name' />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse>
            <ul className='nav navbar-nav navbar-right'>
              <li className='nav-item'>
                <a href='https://www.dailykarma.com/' className='nav-link'>
                  FOR BRANDS
                </a>
              </li>
              <li className='nav-item'>
                <a href='https://www.dailykarma.com/about-us/' className='nav-link'>
                  ABOUT
                </a>
              </li>
            </ul>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </ScrollNav>
  );
}

export default Header;
