import React from "react";

function Footer() {
  function renderCompanyInfo(isMobile: boolean) {
    return (
      <div className={isMobile ? "visible-xs-block first-block" : "hidden-xs"}>
        <h4>COMPANY</h4>
        <a href='https://www.dailykarma.com/' target='_blank' className='logo-link'>
          <i className='icon-logo-name' />
        </a>
        <p className='text-small'>
          DailyKarma is committed to enriching society with the spirit of giving & gratitude through the innovative use of technology.
        </p>
      </div>
    );
  }

  function renderMoreLinks() {
    return (
      <div>
        <h4>MORE</h4>
        <p className='text-small'>
          <a href='https://www.dailykarma.com/about-us/' target='_blank'>
            About
          </a>
        </p>
        <p className='text-small'>
          <a href='https://www.dailykarma.com/blog/' target='_blank'>
            Blog
          </a>
        </p>
        <p className='text-small'>
          <a href='https://www.dailykarma.com/terms-of-service/' target='_blank'>
            Terms of Service
          </a>
        </p>
        <p className='text-small'>
          <a href='https://www.dailykarma.com/privacy-policy/' target='_blank'>
            Privacy Policy
          </a>
        </p>
        <p className='text-small'>
          <a href='https://www.dailykarma.com/' target='_blank'>
            For Brands
          </a>
        </p>
        <p className='text-small'>
          <a href='https://www.dailykarma.com/how-it-works/' target='_blank'>
            Campaigns
          </a>
        </p>
        <p className='text-small'>
          <a href='https://dailykarma.freshdesk.com/support/home' target='_blank'>
            FAQ
          </a>
        </p>
      </div>
    );
  }

  function renderSecurityInfo() {
    return (
      <div>
        <h4>SECURED BY</h4>
        <div>
          <a href='https://stripe.com' target='_blank'>
            <img src='/images/footer/CF_FOOTER_pay_icons_stripe.png' alt='Stripe badge' className='pay-image' />
          </a>
        </div>
      </div>
    );
  }

  function renderContacts(isMobile: boolean) {
    return (
      <div className={isMobile ? "visible-xs-block last-block" : "hidden-xs"}>
        <h4>CONTACT</h4>
        <a href='https://www.facebook.com/getdailykarma' target='_blank'>
          <img src='/images/footer/CF_FOOTER_fb.svg' alt='Facebook logo' className='social-icons' />
        </a>
        <a href='https://twitter.com/getdailykarma' target='_blank'>
          <img src='/images/footer/CF_FOOTER_tw.svg' alt='Twitter logo' className='social-icons' />
        </a>
        <a href='https://www.instagram.com/getdailykarma/' target='_blank'>
          <img src='/images/footer/CF_FOOTER_insta.svg' alt='Instagram logo' className='social-icons' />
        </a>
        <address>
          <p className='text-small'>8605 Santa Monica Blvd, PMB 951</p>
          <p className='text-small'>West Hollywood, CA 90069</p>
          <p className='text-small'>
            <strong>
              <a href='mailto:support@dailykarma.com'>support@dailykarma.com</a>
            </strong>
          </p>
        </address>
      </div>
    );
  }

  return (
    <footer className='general-footer'>
      <div className='container'>
        <div className='row'>{renderCompanyInfo(true)}</div>
        <div className='row'>
          {renderCompanyInfo(false)}
          {renderMoreLinks()}
          {renderSecurityInfo()}
          {renderContacts(false)}
        </div>
        <div className='row'>{renderContacts(true)}</div>
      </div>
    </footer>
  );
}

export default Footer;
