import { API_URL, MIXPANEL_TOKENS, MODE } from "./config";

export const getApiUrl = (): string => {
  switch (process.env.REACT_APP_ENV) {
    case MODE.QA:
      return API_URL[MODE.QA];
    case MODE.PROD:
      return API_URL[MODE.PROD];
    // using QA by default - can be changed in package.json
    default:
      return API_URL[MODE.LOCAL];
  }
};

export const getMixpanelToken = (): string => {
  switch (process.env.REACT_APP_ENV) {
    case MODE.QA:
      return MIXPANEL_TOKENS[MODE.QA];
    case MODE.PROD:
      return MIXPANEL_TOKENS[MODE.PROD];
    // using QA by default - can be changed in package.json
    default:
      return MIXPANEL_TOKENS[MODE.LOCAL];
  }
};
