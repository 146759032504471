import React, { useEffect, useState } from "react";

function GDPR() {
  const [cookieAccept, setCookieAccept] = useState<boolean>(true);

  useEffect(() => {
    const localStatus = localStorage.getItem("gdprStatus");
    if (localStatus) {
      setCookieAccept(true);
    } else {
      setCookieAccept(false);
    }
  }, [setCookieAccept]);
  return cookieAccept ? null : (
    <div className='container-fluid gdpr-container'>
      <div className='row gdpr-content'>
        <p className='gdpr-title'>This website uses cookies in order to ensure you get the best experience on our website</p>
        <button
          className='gdpr-btn'
          onClick={() => {
            localStorage.setItem("gdprStatus", "true");
            setCookieAccept(true);
          }}
        >
          i accept
        </button>
      </div>
    </div>
  );
}

export default GDPR;
