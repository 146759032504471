import React from "react";
import { Outlet, Link } from "react-router-dom";
import Footer from "./components/Footer";
import GDPR from "./components/GDPR";
import Header from "./components/Header";

function App() {
  return (
    <div className='App'>
      <Header />
      <div>
        <Outlet />
      </div>
      <Footer />
      <GDPR />
    </div>
  );
}

export default App;
