import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className='noroute-page'>
      <div className='container'>
        <div className='noroute-go-back'>
          <Link to='/'>
            <a className='back-link'>
              <img src='/images/noRoute/backArrow.svg' alt='Navigation back arrow' /> Back
            </a>
          </Link>
        </div>
        <div className='notice-404-numbers'>
          <strong>404</strong>
        </div>
        <div className='notice-404'>Page Not Found</div>
      </div>
    </div>
  );
}

export default NotFound;
