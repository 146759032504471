import React from "react";
import HomeSlider from "../components/HomeSlider";

function Home() {
  function renderSlider() {
    return (
      <div className='row'>
        <HomeSlider />
        <div className='logo-badge'>
          <img src='/images/home/home-logo-badge.png' alt='Site logo' className='sec-logo-img' />
        </div>
      </div>
    );
  }

  function renderGetInTouch() {
    return (
      <div className='row'>
        <div className='statistic-block'>
          <div className='container'>
            <p className='pull-quote-text label-text'>
              DailyKarma builds custom tools for brands to engage customers in their charitable giving.
            </p>
            <p className='pull-quote-sub label-text'>
              Our streamlined tools embed directly into e-commerce stores to increase customer engagement while they shop. Brands can
              quickly create giving campaigns year round for planned promotions or in response to a current event.
            </p>
            <p className='pull-quote-link label-text'>
              <a href='https://www.dailykarma.com/'>LEARN MORE</a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  function renderInNews() {
    const newsLinks = [
      {
        url: "https://www.forbes.com/sites/forbesnonprofitcouncil/2018/07/11/take-it-from-a-millennial-how-we-feel-about-charity-is-drastically-different-than-our-parents/#154b2d753a4f",
        imageUrl: "/images/home/CF_news_forbes.png",
        className: "item-forbes",
      },
      {
        url: "https://www.rd.com/culture/ways-to-give-to-charity",
        imageUrl: "/images/home/CF_news_RD.png",
        className: "item-rd",
      },
      {
        url: "https://losangeles.cbslocal.com/video/category/spoken-word-kcaltv/3725200-go-to-girlfriend-on-ways-to-help-hurricane-harvey-victims/",
        imageUrl: "/images/home/CF_news_0209.png",
        className: "item-cbs",
      },
      {
        url: "https://www.inc.com/samira-far/heres-why-128-dollars-is-average-online-donation-amount.html",
        imageUrl: "/images/home/CF_news_inc.png",
        className: "item-inc",
      },
      {
        url: "https://www.businessnewsdaily.com/10461-patricia-dao-dailykarma-social-entrepreneurship.html",
        imageUrl: "/images/home/CF_news_BNewsDaily.png",
        className: "item-bnd",
      },
      {
        url: "https://www.ocregister.com/2017/10/02/las-vegas-shooting-how-to-help/",
        imageUrl: "/images/home/CF_news_register.png",
        className: "item-register",
      },
    ];

    return (
      <div className='row'>
        <div className='in-the-news-block'>
          <div className='container'>
            <h3 className='news-head text-center'>DAILYKARMA IN THE NEWS</h3>
            <div className='news-items'>
              {newsLinks.map((newsLink, index) => (
                <a href={newsLink.url} target='_blank' key={index} className={newsLink.className}></a>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className='home-page'>
      <div className='container-fluid'>
        {renderSlider()}
        {renderGetInTouch()}
        {renderInNews()}
      </div>
    </div>
  );
}

export default Home;
